import { Type } from "class-transformer";
import { ValidateNested } from "class-validator";
import { Role } from "../User";
import {
  IGetTotalCountUsersResponseDto,
  IGetCountUsersByRoleResponseDto,
  IGetActiveUsersByDayResponseDto,
  IGetMeasurementsByDayResponseDto,
  IGetMeasurementsDeviceByDayResponseDto,
  IGetReadDeviceByDayResponseDto,
  IGetTranslateByDayResponseDto,
  IItemReadDeviceResponse,
  IReadDeviceImageResponse,
  IExternalAiResponse,
  IInternalAiResponse,
  IReadDeviceOnlyImageResponse,
  ReadDevicesImages,
  IGetReadDeviceByDayCompanyResponseDto,
} from "./ICmsDashboard";
import { Mode } from "../Images";
import { GetMeasurementResponseDto } from "../Measurements/Measurements";
import { GetDeviceResponseDto } from "../Devices";
import { TranslationStatus } from "../TranslateImage";
import { IGetMeasurementResponseValueUnitDto } from "../Measurements/IMeasurements";
import { IGetSignResponseDto } from "../Sign/ISign";

export enum FilterOption {
  INCLUDE = "include",
  EXCLUDE = "exclude",
  ONLY = "only",
}

export class GetTotalCountUsersResponseDto
  implements IGetTotalCountUsersResponseDto
{
  totalCount: number = 0;
}

export class GetCountUsersByRoleResponseDto
  implements IGetCountUsersByRoleResponseDto
{
  role: Role = Role.user;
  count: number = 0;
}

export class GetActiveUsersByDayResponseDto
  implements IGetActiveUsersByDayResponseDto
{
  day: string = "";
  count: number = 0;
}

export class GetMeasurementsDeviceByDayResponseDto
  implements IGetMeasurementsDeviceByDayResponseDto
{
  device: string = "";
  count: number = 0;
}

export class GetMeasurementsByDayResponseDto
  implements IGetMeasurementsByDayResponseDto
{
  day: string = "";
  totalCount: number = 0;

  @ValidateNested()
  @Type(() => GetMeasurementsDeviceByDayResponseDto)
  devices: GetMeasurementsDeviceByDayResponseDto[] = [];
}

export class GetReadDeviceByDayResponseDto
  implements IGetReadDeviceByDayResponseDto
{
  day: string = "";
  device: string = "";
  count: number = 0;
  countTranslateImage: number = 0;
  countDeleteImage: number = 0;
  countTranslateImageExternalAi: number = 0;
  countDeleteImageExternalAi: number = 0;
  countConfirmedImageExternalAi: number = 0;
}

export class GetTranslateByDayResponseDto
  implements IGetTranslateByDayResponseDto
{
  day: string = "";
  device: string = "";
  count: number = 0;
}

export class ItemReadDeviceResponse implements IItemReadDeviceResponse {
  id: number = 0;
  originalDevice?: string | undefined;
  device: string = "";
  created_at: Date = new Date();
  translate_image: boolean = true;
  mode: Mode | null = Mode.SEVENSEGMENT;
  translated_at?: Date | undefined;
  translation_status?: TranslationStatus | undefined;
  externalAiResponse?: IExternalAiResponse | undefined;
  internalAiResponse?: IInternalAiResponse | undefined;
  measurementsInRead: IGetMeasurementResponseValueUnitDto[] | [] = [];
  sign?: IGetSignResponseDto | undefined;
  type_read_device?: string | undefined;
}

export class ExtendedItemReadDeviceResponse
  extends ItemReadDeviceResponse
  implements IItemReadDeviceResponse
{
  translator1_id?: number = 0;
  translator2_id?: number = 0;
  company_name: string = "";
  taken_by_id: number = 0;
  patient_id: number = 0;
}

export class ReadDeviceImageResponse implements IReadDeviceImageResponse {
  imagesUrl: ReadDevicesImages = {};
  measurements: GetMeasurementResponseDto[] = [];
  device: GetDeviceResponseDto = new GetDeviceResponseDto();
}

export class ReadDeviceOnlyImageResponse
  implements IReadDeviceOnlyImageResponse
{
  imagesUrl: ReadDevicesImages = {};
}

export class GetSankeyEventsResponseData {
  source!: string;
  timestamp!: string;
}
export class GetSankeyTracesResponseData {
  source!: string;
  target!: string;
  value!: number;
  time?: number; // trace time in milliseconds
}

export class GetSankeyGraphResponseData {
  traces!: GetSankeyTracesResponseData[];
  events?: GetSankeyEventsResponseData[];
}

export class GetReadDeviceByDayCompanyResponseDto
  implements IGetReadDeviceByDayCompanyResponseDto
{
  day: string = "";
  company: string = "";
  count: number = 0;
  countTranslateImage: number = 0;
  countDeleteImage: number = 0;
  countTranslateImageExternalAi: number = 0;
  countDeleteImageExternalAi: number = 0;
  countConfirmedImageExternalAi: number = 0;
}