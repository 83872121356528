import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import useStyles from './styles'
import { useApi } from '@doctomatic/sdk/build/Api'
import { AppBar, Toolbar, Typography, Box, IconButton, Menu, MenuItem, PopoverOrigin, ListItem } from '@material-ui/core'
import { AccountCircle, Menu as MenuIcon } from '@material-ui/icons'
import { useLocation } from 'react-router-dom'
import { Role } from '@doctomatic/sdk/build/dto/User'
import { processError } from '../../App/errorToast'

const Appbar = (): React.ReactElement => {
  const { useProfile, logout } = useApi()
  const history = useNavigate()
  const location = useLocation()
  const classes = useStyles()
  const { response: profileResponse } = useProfile(false, true, processError(logout, history))
  const profile = profileResponse?.data;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null)
  const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'center' }
  const menuAnchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'center' }

  const handleClose = () => setAnchorEl(null)
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)

  const handleMenuClose = () => setMenuAnchor(null)
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => setMenuAnchor(event.currentTarget)

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('filtrosReadDevice');
    localStorage.removeItem('filtrosDashboard');
    logout()
  }

  if (!profile) return <></>

  const showButtons =
    profile.hasPassword && // el usuario tiene que tener password
    location.pathname !== '/recover' // la página de recover password no muestra botones en el menú

  const buttons = (
    <>
      <Box className={classes.appBarLinksMobile}>
        <Box flexGrow={1}>
          {profile && profile.role === Role.superadmin && (<>
            <NavLink className={classes.link} to="/companies">Centros médicos</NavLink>
            <NavLink className={classes.link} to="/dashboard">Dashboard</NavLink>
            <NavLink className={classes.link} to="/tickets">Tickets</NavLink>
            <NavLink className={classes.link} to="/legal">Legal Docs</NavLink>
            <NavLink className={classes.link} to="/devices">Dispositivos</NavLink>
            <NavLink className={classes.link} to="/ranking">Ranking</NavLink>
            <NavLink className={classes.link} to="/read_devices_viewer">Visor Lecturas</NavLink>
            <NavLink className={classes.link} to="/status">Estado</NavLink>
          </>)}
          {profile && profile.role === Role.superadmin && process.env.REACT_APP_VALIDATE === 'true' && (
            <NavLink className={classes.link} to="/validator">Validación BD</NavLink>
          )}
          {profile && profile.role === Role.translatorimage && (<>
            <NavLink className={classes.link} to="/translations">Traducciones</NavLink>
            <NavLink className={classes.link} to="/ranking">Ranking</NavLink>
          </>)}
        </Box>
        <IconButton onClick={handleProfileMenuOpen} color="inherit" ><AccountCircle /></IconButton>
      </Box>
    </>
  )

  const menuIcon = (
    <>
      <IconButton className={classes.menuLinks} onClick={handleMenuOpen} color="inherit" ><MenuIcon /></IconButton>
    </>
  )

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbarMobile}>
        <Box mr={4} minWidth={'fit-content'}>
          <Typography variant="h6">Doctomatic CMS</Typography>
        </Box>

        {showButtons && buttons}
        {showButtons && menuIcon}

        <Menu anchorEl={anchorEl} getContentAnchorEl={null} keepMounted open={Boolean(anchorEl)} onClose={handleClose} anchorOrigin={anchorOrigin}>
          <ListItem>
            <Box textAlign='center' fontWeight='700'>
              {profile.name}<br />{profile.email}
            </Box>
          </ListItem>
          <MenuItem onClick={handleClose}>
            <Box textAlign='right' width='100%' onClick={handleLogout}>Logout</Box>
          </MenuItem>
        </Menu>
        <Menu anchorEl={menuAnchor} getContentAnchorEl={null} keepMounted open={Boolean(menuAnchor)} onClose={handleMenuClose} anchorOrigin={menuAnchorOrigin}>
          <ListItem>
            <Box textAlign='center' fontWeight='700'>
              {profile.name}<br />{profile.email}
            </Box>
          </ListItem>
          {profile && profile.role === Role.superadmin && (
            <>
              <ListItem onClick={handleMenuClose}>
                <NavLink className={classes.link} to="/companies">Centros médicos</NavLink>
              </ListItem>
              <ListItem onClick={handleMenuClose}>
                <NavLink className={classes.link} to="/dashboard">Dashboard</NavLink>
              </ListItem>
              <ListItem onClick={handleMenuClose}>
                <NavLink className={classes.link} to="/tickets">Tickets</NavLink>
              </ListItem>
              <ListItem onClick={handleMenuClose}>
                <NavLink className={classes.link} to="/legal">Legal Docs</NavLink>
              </ListItem>
              <ListItem onClick={handleMenuClose}>
                <NavLink className={classes.link} to="/devices">Dispositivos</NavLink>
              </ListItem>
            </>
          )}
          {profile && profile.role === Role.translatorimage && (
            <>
              <ListItem onClick={handleMenuClose}>
                <NavLink className={classes.link} to="/translations">Traducciones</NavLink>
              </ListItem>
            </>
          )}
          <ListItem onClick={handleMenuClose}>
            <NavLink className={classes.link} to="/ranking">Ranking</NavLink>
          </ListItem>
          <MenuItem onClick={handleClose}>
            <Box textAlign='right' width='100%' onClick={handleLogout}>Logout</Box>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export { Appbar }
