import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { useApi } from "@doctomatic/sdk/build/Api";
import useStyles from "./styles";
import { CircularProgress, Link } from "@material-ui/core";
import { RecoverPasswordRequestDto } from "@doctomatic/sdk/build/dto/Mail";
import { validate, ValidationError } from "class-validator";
import { processError } from "../errorToast";

const ForgotPassword = (): React.ReactElement => {
  const history = useNavigate();
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { useMail, logout } = useApi();
  const { sendRecoverPasswordEmail } = useMail(
    true,
    processError(logout, history)
  );

  useEffect(() => {
    checkErrors();
  }, [email]);

  const checkErrors = async () => {
    const dto = new RecoverPasswordRequestDto();
    dto.email = email;
    const errors = await validate(dto);
    setErrors(errors);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const dto = new RecoverPasswordRequestDto();
      dto.email = email;
      const response = await sendRecoverPasswordEmail(dto);
      if (response.success) setShowSuccess(true);
    } catch (err: any) {
      // toast.error(err.response?.data?.message || err.message)
    }
    setLoading(false);
  };

  const success = (
    <>
      <Typography style={{ marginTop: 40, marginBottom: 20, fontSize: 36 }}>
        Listo! 👍
      </Typography>
      <Typography style={{ marginBottom: 20 }}>
        Hemos enviado un email a tu bandeja de correo
      </Typography>
      <Link component={NavLink} to="/">
        Volver
      </Link>
    </>
  );

  const form = (
    <form
      id="login"
      className={classes.form}
      noValidate
      onSubmit={handleSubmit}
    >
      <Typography style={{ marginTop: 20 }}>
        Indícanos el email con el que estás registrado en Doctomatic.
      </Typography>
      <Typography style={{ marginTop: 20 }}>
        Te enviaremos por email las instrucciones para establecer una nueva
        contraseña
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
        onChange={(event) => setEmail(event.target.value)}
        value={email}
      />
      <Button
        disabled={errors.length > 0 || loading}
        id="button"
        type="submit"
        fullWidth
        variant="contained"
        className={classes.submit}
      >
        {loading ? <CircularProgress /> : "Enviar email de recuperación"}
      </Button>
    </form>
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography style={{ marginBottom: 20 }} variant="h1">
          Recuperación de contraseña.
        </Typography>
        {showSuccess ? success : form}
      </div>
    </Container>
  );
};

export { ForgotPassword };
