import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { Page } from "../../Page";
import { GetReadDeviceByDayCompanyResponseDto } from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";
import { MeasurementsDayCompanyGridColumns } from "./MeasurementsDayCompanyGridColumns";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect } from "react";

export interface IMeasurements {
  id: number;
  day: string;
  company: string;
  count: number;
  countTranslateImage: number;
  countDeleteImage: number;
}

export interface IMeasurementsGrid {
  measurements?: GetReadDeviceByDayCompanyResponseDto[]; // Puede ser opcional para evitar errores
}

const MeasurementsDayCompanyGrid = ({
  measurements,
}: IMeasurementsGrid): React.ReactElement => {
  const measurementsDataRows: IMeasurements[] | undefined = measurements?.map(
    (measurement: GetReadDeviceByDayCompanyResponseDto, index: number) => ({
      id: index,
      day: measurement.day ?? "", // Manejo de valores nulos
      company: measurement.company ?? "",
      count: measurement.count ?? 0,
      countTranslateImage: measurement.countTranslateImage ?? 0,
      countDeleteImage: measurement.countDeleteImage ?? 0,
    })
  );

  const measurementsColumns: GridColDef<IMeasurements>[] =
    MeasurementsDayCompanyGridColumns();

  return (
    <Page title="Lecturas totales" primaryColor="black">
      <FlexLayoutGrid
        columns={measurementsColumns as any}
        rows={measurementsDataRows ?? []}
      />
    </Page>
  );
};

export { MeasurementsDayCompanyGrid };
