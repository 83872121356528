import { makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  CompanyType,
  ObjectPrivacy,
  UpdateCompanyCmsRequestDto,
} from "@doctomatic/sdk/build/dto/Company";
import { useApi } from "@doctomatic/sdk/build/Api";
import { processError } from "../../../App/errorToast";
import { useNavigate } from "react-router-dom";
import WebFont from "webfontloader";

interface CompanyEditProps {
  open: boolean;
  companyToEditId: number;
  onClose: () => void;
  onSave: (company: UpdateCompanyCmsRequestDto) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  visible: { overflowY: "visible" },
  error: {
    bottom: 0,
    margin: 0,
    fontSize: "12px",
    color: theme.palette.error.main,
  },
  scrollable: {
    overflowY: "auto",
    maxHeight: "400px", // Ajusta la altura máxima según tus necesidades
  },
  scrollContent: {
    padding: theme.spacing(2),
  },
}));

const CompanyEdit = ({
  open,
  companyToEditId,
  onClose,
  onSave,
}: CompanyEditProps): React.ReactElement => {
  const history = useNavigate();
  const { useCompany, logout } = useApi();
  const { response: company } = useCompany(
    companyToEditId,
    true,
    processError(logout, history)
  );
  const companyToEdit = company?.data;
  const [name, setName] = useState(companyToEdit?.name);
  const [fee, setFee] = useState(companyToEdit?.fee);
  const [onBoardingReq, setOnBoardingReq] = useState(
    companyToEdit?.onBoardingReq
  );
  const [tax, setTax] = useState(companyToEdit?.tax);
  const [stripeAccount, setStripeAccount] = useState(
    companyToEdit?.stripeAccount
  );
  const [stripePriceId, setStripePriceId] = useState(
    companyToEdit?.stripePriceId
  );
  const [trialCode, setTrialCode] = useState(companyToEdit?.trialCode);
  const [trialDays, setTrialDays] = useState(companyToEdit?.trialDays);
  const [taxId, setTaxId] = useState(companyToEdit?.taxId);
  const [maxCost, setMaxCost] = useState(companyToEdit?.maxCost);
  const [maxPatientsWithoutSubscription, setMaxPatientsWithoutSubscription] =
    useState(companyToEdit?.maxPatientsWithoutSubscription);
  const [buttonColor, setButtonColor] = useState(companyToEdit?.buttonColor);
  // const [logo, setLogo] = useState(companyToEdit?.logo);
  const [font, setFont] = useState<string>(companyToEdit?.font as string);
  const [readDeviceDescription, setReadDeviceDescription] = useState<boolean>(
    companyToEdit?.readDeviceDescription ?? false
  );
  const [disabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState({ trialCode: false, trialDays: false });
  const [googleFonts, setGoogleFonts] = useState<string[]>([]);
  const [description, setDescription] = useState<string>();
  const [autoRead, setAutoRead] = useState<boolean>(
    companyToEdit?.autoRead ?? false
  );
  const [companyType, setCompanyType] = useState<CompanyType | null>(
    companyToEdit?.companyType ?? null
  );
  const [objectsPrivacy, setObjectsPrivacy] = useState<ObjectPrivacy>(
    companyToEdit?.objectsPrivacy ?? ObjectPrivacy.private
  );

  useEffect(() => {
    setName(companyToEdit?.name);
    setFee(companyToEdit?.fee);
    setOnBoardingReq(companyToEdit?.onBoardingReq);
    setTax(companyToEdit?.tax);
    setStripeAccount(companyToEdit?.stripeAccount);
    setStripePriceId(companyToEdit?.stripePriceId);
    setTrialCode(companyToEdit?.trialCode);
    setTrialDays(companyToEdit?.trialDays);
    setTaxId(companyToEdit?.taxId);
    setMaxPatientsWithoutSubscription(
      companyToEdit?.maxPatientsWithoutSubscription
    );
    setButtonColor(companyToEdit?.buttonColor);
    // setLogo(companyToEdit?.logo);
    setFont(companyToEdit?.font as string);
    setDescription(companyToEdit?.description);
    setAutoRead(companyToEdit?.autoRead ?? false);
    setReadDeviceDescription(companyToEdit?.readDeviceDescription ?? false);
    setCompanyType(companyToEdit?.companyType ?? null);
    setObjectsPrivacy(companyToEdit?.objectsPrivacy ?? ObjectPrivacy.private)
  }, [open, companyToEdit]);

  useEffect(
    () => setDisabled(Object.values(errors).reduce((acc, curr) => acc || curr)),
    [errors]
  );

  useEffect(() => {
    if (trialCode && trialCode.length < 2) {
      const trialDaysError = trialDays === 0 || !trialDays;
      setErrors({ trialCode: true, trialDays: trialDaysError });
    } else if (trialDays && trialDays !== 0) {
      const trialCodeError = !trialCode || trialCode.length === 0;
      setErrors({ trialCode: trialCodeError, trialDays: false });
    } else if (!trialCode && (trialDays === 0 || !trialDays)) {
      setErrors({ trialDays: false, trialCode: false });
    } else {
      if (!trialDays || trialDays === 0)
        setErrors({ trialCode: false, trialDays: true });
    }
  }, [trialCode, trialDays]);

  useEffect(() => {
    // Fetch Google Fonts API to get the available fonts
    fetch(
      `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_FONTS_API_KEY}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (!data.items) return;
        const fonts = data.items.map((font: any) => font.family);
        setGoogleFonts(fonts);
      })
      .catch((error) => console.error("Error fetching Google Fonts:", error));
  }, []);

  const classes = useStyles();

  // TODO - upload img company
  // const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const selectedFile = event.target.files?.[0];
  //   if (selectedFile) {
  //     if (selectedFile.type.indexOf("image/") === -1) {
  //       alert("Solo se permiten fotos");
  //       return;
  //     }

  //     // Create a new FileReader
  //     const reader = new FileReader();
  //     reader.readAsDataURL(selectedFile);

  //     // Wait for the FileReader to load the image
  //     reader.onload = () => {
  //       const img = new Image();
  //       img.onload = () => {
  //         if (img.width > 250 || img.height > 250) {
  //           alert("La imagen debe tener un tamaño máximo de 250x250 píxeles");
  //           return;
  //         }
  //         setLogoFile(selectedFile);
  //       };
  //       img.src = reader.result as string;
  //     };

  //     // Handle errors
  //     reader.onerror = () => {
  //       alert("No se pudo leer el archivo");
  //       return;
  //     };
  //   }
  // };

  const handleFontChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedFont = event.target.value as string;
    WebFont.load({
      google: {
        families: [selectedFont],
      },
    });

    setFont(selectedFont);
  };

  const handleCompanyTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    console.log(event.target.value);
    const companyType = event.target.value as string;
    setCompanyType(companyType as CompanyType);
  };

  const handleObjectPrivacyChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const objectsPrivacy = event.target.value as string;
    setObjectsPrivacy(objectsPrivacy as ObjectPrivacy);
  };

  const handleOnBoardChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedOnBoardType = event.target.value as string;
    if (!selectedOnBoardType || selectedOnBoardType.length === 0) return;
    if (+selectedOnBoardType > 4 || +selectedOnBoardType < 1) return;
    setOnBoardingReq(+selectedOnBoardType);
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      classes={{ paper: classes.visible }}
    >
      <DialogTitle>Editar centro médico</DialogTitle>

      <DialogContent className={`${classes.visible} ${classes.scrollable}`}>
        <div className={classes.scrollContent}>
          <TextField
            autoComplete="off"
            margin="dense"
            variant="outlined"
            name="name"
            label="Name"
            type="text"
            fullWidth
            onChange={(event) => setName(event.target.value)}
            value={name || ""}
            autoFocus
          />
          <TextField
            autoComplete="off"
            margin="dense"
            variant="outlined"
            name="description"
            label="Description"
            inputProps={{ maxLength: 255 }}
            type="text"
            fullWidth
            onChange={(event) => setDescription(event.target.value)}
            value={description || ""}
            autoFocus
          />
          <TextField
            autoComplete="off"
            margin="dense"
            variant="outlined"
            name="maxCost"
            label="maxCost"
            type="number"
            fullWidth
            onChange={(event) => setMaxCost(+event.target.value)}
            value={maxCost || ""}
          />
          <TextField
            autoComplete="off"
            margin="dense"
            variant="outlined"
            name="maxPatientsWithoutSubscription"
            label="Max patients without subscription"
            type="number"
            fullWidth
            onChange={(event) => {
              setMaxPatientsWithoutSubscription(+event.target.value);
            }}
            value={maxPatientsWithoutSubscription || ""}
            InputProps={{ inputProps: { min: 0 } }}
          />
          <TextField
            autoComplete="off"
            margin="dense"
            variant="outlined"
            name="buttonColor"
            label="Corporate color"
            type="color"
            fullWidth
            onChange={(event) => {
              setButtonColor(event.target.value);
            }}
            value={buttonColor || ""}
          />
          {/* <InputLabel htmlFor='LogoInput'>Corporate logo</InputLabel>
          <Input autoComplete='off' margin='dense' name='LogoFile' type='file' fullWidth onChange={handleFileInputChange} /> */}
          <TextField
            select
            margin="dense"
            variant="outlined"
            name="fontFamily"
            label="Font Family"
            type="text"
            fullWidth
            onChange={handleFontChange}
            value={font || ""}
            InputProps={{
              style: {
                fontFamily: font,
              },
            }}
          >
            {googleFonts.map((fontGoogle) => (
              <MenuItem
                key={fontGoogle}
                style={{ fontFamily: fontGoogle ? fontGoogle : "" }}
                value={fontGoogle}
              >
                {fontGoogle}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            autoComplete="off"
            margin="dense"
            variant="outlined"
            name="fee"
            label="Fee"
            type="text"
            fullWidth
            onChange={(event) => setFee(+event.target.value)}
            value={fee || ""}
          />
          <TextField
            select
            margin="dense"
            variant="outlined"
            name="onBoardingReq"
            label="On board type"
            type="text"
            fullWidth
            onChange={handleOnBoardChange}
            value={onBoardingReq || ""}
          >
            {[1, 2, 3, 4].map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            autoComplete="off"
            margin="dense"
            variant="outlined"
            name="tax"
            label="Tax"
            type="text"
            fullWidth
            onChange={(event) => setTax(+event.target.value)}
            value={tax || ""}
          />
          <TextField
            autoComplete="off"
            margin="dense"
            variant="outlined"
            name="stripeAccount"
            label="Stripe account id"
            type="text"
            fullWidth
            onChange={(event) => setStripeAccount(event.target.value)}
            value={stripeAccount || ""}
          />
          <TextField
            autoComplete="off"
            margin="dense"
            variant="outlined"
            name="stripePriceId"
            label="Stripe price id"
            type="text"
            fullWidth
            onChange={(event) => setStripePriceId(event.target.value)}
            value={stripePriceId || ""}
          />
          <TextField
            autoComplete="off"
            margin="dense"
            variant="outlined"
            name="trialCode"
            label="Promo code"
            type="text"
            fullWidth
            onChange={(event) => {
              setTrialCode(event.target.value);
            }}
            value={trialCode || ""}
          />
          {errors.trialCode && (
            <Typography className={classes.error} style={{ marginBottom: 12 }}>
              Campo requerido. El código debe tener 2 carácteres.
            </Typography>
          )}
          <TextField
            autoComplete="off"
            margin="dense"
            variant="outlined"
            name="trialDays"
            label="Trial days"
            type="number"
            fullWidth
            onChange={(event) => {
              setTrialDays(+event.target.value);
            }}
            value={trialDays || ""}
          />
          {errors.trialDays && (
            <Typography className={classes.error} style={{ marginBottom: 12 }}>
              Campo requerido. Valor mínimo de 1.
            </Typography>
          )}
          <TextField
            autoComplete="off"
            margin="dense"
            variant="outlined"
            name="taxId"
            label="Tax id"
            type="text"
            fullWidth
            onChange={(event) => setTaxId(event.target.value)}
            value={taxId || ""}
          />
          <TextField
            select
            margin="dense"
            variant="outlined"
            name="companyType"
            label="Company type"
            type="text"
            fullWidth
            onChange={handleCompanyTypeChange}
            value={companyType || ""}
          >
            {Object.values(CompanyType).map((companyType) => (
              <MenuItem key={companyType} value={companyType}>
                {companyType.charAt(0).toUpperCase() + companyType.slice(1)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            margin="dense"
            variant="outlined"
            name="objectsPrivacy"
            label="Objects privacy"
            type="text"
            fullWidth
            onChange={handleObjectPrivacyChange}
            value={objectsPrivacy}
          >
            {Object.values(ObjectPrivacy).map((objectPrivacy) => (
              <MenuItem key={objectPrivacy} value={objectPrivacy}>
                {objectPrivacy.charAt(0).toUpperCase() + objectPrivacy.slice(1)}
              </MenuItem>
            ))}
          </TextField>
          <FormControlLabel
            control={
              <Checkbox
                style={{ padding: "0 9px " }}
                checked={autoRead}
                onChange={(event) => setAutoRead(event.target.checked)}
              />
            }
            label={<Typography>Habilitar lectura automática</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                style={{ padding: "0 9px " }}
                checked={readDeviceDescription}
                onChange={(event) =>
                  setReadDeviceDescription(event.target.checked)
                }
              />
            }
            label={
              <Typography>
                Habilitar envío de descripción para cualquier lectura
              </Typography>
            }
          />
        </div>
      </DialogContent>

      <DialogActions>
        <Box m={2}>
          <Box mr={2} display="inline-block">
            <Button onClick={onClose} color="default" size="small">
              Cancelar
            </Button>
          </Box>

          <Button
            disabled={disabled}
            onClick={() => {
              const dto = new UpdateCompanyCmsRequestDto();
              dto.name = name;
              dto.description = description;
              dto.fee = fee;
              dto.tax = tax;
              dto.stripeAccount = stripeAccount;
              dto.stripePriceId = stripePriceId;
              dto.trialCode = trialCode;
              dto.trialDays = trialDays;
              dto.taxId = taxId;
              dto.maxPatientsWithoutSubscription =
                maxPatientsWithoutSubscription;
              dto.maxCost = maxCost;
              dto.buttonColor = buttonColor;
              // TODO: improve update. Send only updated fields
              // dto.logo = logo;
              dto.font = font;
              dto.onBoardingReq = onBoardingReq;
              dto.autoRead = autoRead;
              dto.companyType = companyType ?? undefined;
              dto.readDeviceDescription = readDeviceDescription;
              dto.objectsPrivacy = objectsPrivacy
              onSave(dto);
            }}
            variant="contained"
            size="small"
          >
            Guardar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export { CompanyEdit };
