import React from "react";
import { Box, Typography } from "@material-ui/core";

const StatusIndicator = ({ serviceName, isActive }: any): React.ReactElement => {
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginTop={1} marginBottom={1}>
      <Typography variant="h1" component="div" gutterBottom style={{ marginRight: 40 }}>
        {serviceName} status:
      </Typography>
      <Box data-testid={serviceName+'-box'} bgcolor={isActive ? 'lightgreen' : 'red'} marginLeft={'auto'} borderRadius={50} width={16} height={16} />
    </Box>
  );
};

export { StatusIndicator };
