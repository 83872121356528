import {
  GetSankeyEventsResponseData,
  GetSankeyGraphResponseData,
  GetSankeyTracesResponseData,
  ItemReadDeviceResponse,
  ReadDeviceImageResponse,
  ReadDeviceOnlyImageResponse,
} from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";
import { GridSortModel } from "@mui/x-data-grid";
import { useLoading } from "../../Loading/Loading";
import { useState } from "react";
import { CustomImageViewModal } from "@doctomatic/components-react/build/ImagesViews/CustomImageViewModal";
import { useTranslation } from "react-i18next";
import { ImageEditModal } from "@doctomatic/components-react/build/ImagesViews/ImageEditModal";
import {
  BasicMeasurement,
  Device,
} from "@doctomatic/components-react/build/Graphs/models";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { DoctomaticStylingRowsGrid } from "../ImageValidator/utils";
import { ReadDeviceViewerGridColumns } from "./ReadDeviceViewerGridColumns";
import { GetMeasurementResponseDto } from "@doctomatic/sdk/build/dto/Measurements/Measurements";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import {
  ExtendedItemReadDeviceDataRow,
  ItemReadDeviceDataRow,
} from "./ReadDeviceViewerDataRow";
import { Box, Modal } from "@mui/material";
import { styles } from "../styles";
import { DataRange } from "@doctomatic/sdk/build/utils/query/filter.interface";
import { SankeyGraph } from "../Dashboard/SankeyGraph/SankeyGraph";
import { toast } from "react-toastify";

const boxStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  bgcolor: "white",
  boxShadow: 24,
  outline: "none",
  padding: 2,
  borderRadius: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
};

interface Props {
  readDevices: ItemReadDeviceDataRow[] | ExtendedItemReadDeviceDataRow[];
  onPageChange: (newPageNumber: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  paginationInfo?: any;
  getDebugImage: (id: number) => Promise<ResponseApi<string>>;
  getImageAndMeasurements: (
    id: number
  ) => Promise<ResponseApi<ReadDeviceImageResponse>>;
  getSankeyData: (
    companyId?: number,
    deviceId?: number,
    timeZone?: number,
    takeById?: number,
    patientId?: number,
    dateRange?: DataRange<string>,
    readDeviceRange?: DataRange<number>
  ) => Promise<ResponseApi<GetSankeyGraphResponseData>>;
  sortBy?: GridSortModel;
  onSortChange?: (newSort: GridSortModel) => void;
  extendedData?: boolean;
  externalAiResponse?: boolean;
  internalAiResponse?: boolean;
  getImage?: (id: number) => Promise<ResponseApi<ReadDeviceOnlyImageResponse>>;
  showExternal?: boolean;
}

interface ReadDeviceItemInterface extends ItemReadDeviceResponse {
  imageUrl?: string;
}

const ReadDeviceViewerList = ({
  readDevices,
  onPageChange,
  onPageSizeChange,
  paginationInfo,
  getDebugImage,
  getImageAndMeasurements,
  getSankeyData,
  sortBy,
  onSortChange,
  extendedData,
  externalAiResponse,
  internalAiResponse,
  getImage,
  showExternal,
}: Props): React.ReactElement => {
  const { i18n, t } = useTranslation();
  const { setIsLoading, isLoading } = useLoading();

  const [readDeviceItem, setReadDeviceItem] = useState<
    ReadDeviceItemInterface | undefined
  >(undefined);
  const [readDeviceDebugItem, setReadDeviceDebugItem] = useState<
    ReadDeviceItemInterface | undefined
  >(undefined);

  const [error, setError] = useState<boolean>(false);

  const [device, setDevice] = useState<Device>();

  const [measurements, setMeasurements] = useState<GetMeasurementResponseDto[]>(
    []
  );

  const [choices, setChoices] = useState<string[]>([]);

  const [traces, setTraces] = useState<GetSankeyTracesResponseData[]>([]);
  const [events, setEvents] = useState<GetSankeyEventsResponseData[]>([]);

  const handleError = () => {
    setError(true);
  };

  const setRowClassName = (params: any): string => {
    return "";
  };

  const openReadDeviceModal = async (item: ItemReadDeviceDataRow) => {
    setIsLoading(true);
    let newItem: ReadDeviceItemInterface | undefined = undefined;
    try {
      const response = await getImageAndMeasurements(item.id);
      if (response.data) {
        newItem = {
          ...item,
          imageUrl: response.data.imagesUrl.imageUrl
            ? response.data.imagesUrl.imageUrl
            : "",
        };
        setMeasurements(response.data.measurements);
        setDevice(response.data.device);
      }
    } catch (err) {
      newItem = {
        ...item,
        imageUrl: undefined,
      };
      setMeasurements([]);
    } finally {
      setIsLoading(false);
      setReadDeviceItem(newItem);
    }
  };

  const openReadDeviceDebugModal = async (item: ItemReadDeviceDataRow) => {
    let newItem: ReadDeviceItemInterface | undefined = undefined;
    setIsLoading(true);
    try {
      const response = await getDebugImage(item.id);
      if (response.data) {
        newItem = {
          ...item,
          imageUrl: response.data,
        };
      }
    } catch (err) {
      newItem = {
        ...item,
        imageUrl: undefined,
      };
    } finally {
      setReadDeviceDebugItem(newItem);
    }
    try {
      const response = await getSankeyData(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        { start: item.id, end: item.id }
      );
      if (response.data) {
        setTraces(response.data.traces ?? []);
        setEvents(response.data.events ?? []);
      } else {
        toast.info("No data found");
      }
    } catch (err: any) {
      setTraces([]);
      setEvents([]);
    }
    setIsLoading(false);
  };

  const openChoicesModal = async (externalAiChoices: string[]) => {
    setChoices(externalAiChoices);
  };

  const imagesGridColumns = ReadDeviceViewerGridColumns(
    openReadDeviceModal,
    openReadDeviceDebugModal,
    extendedData,
    externalAiResponse,
    internalAiResponse,
    openChoicesModal,
    getImage,
    showExternal
  );

  const showReadDeviceModal = readDeviceItem && device && (
    <ImageEditModal
      open={Boolean(readDeviceItem)}
      handleClose={() => {
        setReadDeviceItem(undefined);
        setDevice(undefined);
        setMeasurements([]);
        setError(false);
      }}
      imageUrl={readDeviceItem.imageUrl ?? ""}
      altText={""}
      t={t}
      error={error}
      handleError={handleError}
      measurements={measurements}
      onSave={async (measurements: BasicMeasurement[]) => {}}
      editPermissions={false}
      deletePermissions={false}
      onDeleteMany={async (readDeviceId: number) => {}}
      onChange={(event: any, signId: number) => {}}
      device={device}
      measurementToDelete={null}
      setMeasurementToDelete={(
        measurement: { id: number; name: string } | null
      ) => {}}
      readDeviceToDelete={false}
      setReadDeviceToDelete={(delReadDevice: boolean) => {}}
      currentLanguage={"es"}
    />
  );

  const showReadDeviceDebugModal = readDeviceDebugItem && (
    <CustomImageViewModal
      open={Boolean(readDeviceDebugItem)}
      handleClose={() => {
        setReadDeviceDebugItem(undefined);
        setError(false);
        setTraces([]);
        setEvents([]);
      }}
      t={t}
      error={error}
      handleError={handleError}
      imageUrl={readDeviceDebugItem.imageUrl ?? ""}
      altText="Imagen debug"
      optionalElement={<SankeyGraph traces={traces} events={events}/>}
    />
  );

  const showChoicesModal = choices && (
    <Modal
      open={choices.length > 0}
      onClose={() => setChoices([])}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styles.containerModal}>
        {choices.map((x) => {
          return <ul style={{ color: "black" }}>{x}</ul>;
        })}
      </Box>
    </Modal>
  );
  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <FlexLayoutGrid
        language={i18n.language}
        columns={imagesGridColumns as any}
        rows={readDevices}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pagination={paginationInfo}
        isLoading={isLoading}
        setRowClassName={setRowClassName}
        sx={DoctomaticStylingRowsGrid() as any}
        sortBy={sortBy}
        onSortChange={onSortChange}
        height={"100%"}
        rowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
        rowHeight={85}
      ></FlexLayoutGrid>
      {showReadDeviceModal}
      {showReadDeviceDebugModal}
      {showChoicesModal}
    </div>
  );
};

export { ReadDeviceViewerList };
