import { Page } from "../../Page";
import { IMeasurementsGrid } from "./MeasurementsGrid";
import { GetReadDeviceByDayResponseDto } from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Customized,
  CustomizedProps
} from "recharts";
import { CircularProgress } from "@mui/material";
import { Box, FormControlLabel, FormGroup } from "@material-ui/core";
import dateFormat from "dateformat";
import Switch from "@material-ui/core/Switch";
import { useState } from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import { colors } from "../../../Theme/Theme";
import { DataRange } from "@doctomatic/sdk/build/utils/query/filter.interface";

const theme = createTheme({
  palette: {
    action: {
      disabledBackground: colors.backgroundGrey,
      disabled: colors.borderGrey,
    },
  },
});

export interface IMeasurementsGraphic extends IMeasurementsGrid {
  deviceId: number | undefined;
  showExternalReadDevice: boolean;
  setShowExternalReadDevice: (value: boolean) => void;
  companyId?: number;
  dateRange?: DataRange<string>;
  readDeviceRange?: DataRange<number>;
}

interface IChartItem {
  dia: string;
  traducidas: number;
  eliminadas: number;
  confirmadas: number;
  traducidasExternalAi: number;
  eliminadasExternalAi: number;
  confirmadasExternalAi: number;
}

const barColors = [
  "#4bae51",
  "#007BB7",
  "#F4771F",
  // ----------------------------------------------------------------
  "#b3dfb6",
  "#52c5ff",
  "#fbd0b1",
];

const hexToRGBA = (hex: string, a: number): string => {
  hex = "0x" + hex.substring(1);
  const r = (+hex >> 16) & 0xff;
  const g = (+hex >> 8) & 0xff;
  const b = +hex & 0xff;
  return `rgba(${r}, ${g}, ${b}, ${a})`;
};

const MeasurementsGraphic = ({
  measurements,
  deviceId,
  showExternalReadDevice,
  setShowExternalReadDevice,
  companyId,
  dateRange,
  readDeviceRange
}: IMeasurementsGraphic): React.ReactElement => {
  const [showExternalAi, setShowExternalAi] = useState<boolean>(false);

  const chart = (data: GetReadDeviceByDayResponseDto[]) => {
    const calculateValues = (
      items: GetReadDeviceByDayResponseDto[],
      day: string
    ): IChartItem => {
      const initialValues: IChartItem = {
        dia: dateFormat(day, "dd/mm/yyyy"),
        traducidas: 0,
        eliminadas: 0,
        confirmadas: 0,
        traducidasExternalAi: 0,
        eliminadasExternalAi: 0,
        confirmadasExternalAi: 0,
      };

      return items.reduce((acc, x) => {
        const {
          countTranslateImage,
          countDeleteImage,
          count,
          countTranslateImageExternalAi,
          countDeleteImageExternalAi,
          countConfirmedImageExternalAi,
        } = x;

        acc.traducidas += showExternalAi
          ? countTranslateImage - countTranslateImageExternalAi
          : countTranslateImage;
        acc.eliminadas += showExternalAi
          ? countDeleteImage - countDeleteImageExternalAi
          : countDeleteImage;
        acc.confirmadas += showExternalAi
          ? count -
          countTranslateImage -
          countDeleteImage -
          countConfirmedImageExternalAi
          : count - countTranslateImage - countDeleteImage;

        acc.traducidasExternalAi += countTranslateImageExternalAi;
        acc.eliminadasExternalAi += countDeleteImageExternalAi;
        acc.confirmadasExternalAi += countConfirmedImageExternalAi;

        return acc;
      }, initialValues);
    };

    const processData = (
      data: GetReadDeviceByDayResponseDto[]
    ): IChartItem[] => {
      if (!deviceId) {
        // Agrupar por día
        const uniqueDays = Array.from(new Set(data.map((x) => x.day))).sort(
          (a, b) => new Date(a).getTime() - new Date(b).getTime()
        );

        return uniqueDays.map((day) => {
          const dailyData = data.filter((x) => x.day === day);
          return calculateValues(dailyData, day);
        });
      }

      // Si hay `deviceId`, procesar los datos directamente ordenados
      const sortedData = data.sort(
        (a, b) => new Date(a.day).getTime() - new Date(b.day).getTime()
      );
      return sortedData.map((x) => calculateValues([x], x.day));
    };

    const renderTotal = (props: any, data: typeof parsedData, isExternalAI: boolean) => {
      const { xAxisMap, yAxisMap, margin } = props;
      if (!xAxisMap || !yAxisMap) return null;

      const xScale = xAxisMap[0]?.scale;
      const yScale = yAxisMap[0]?.scale;

      if (!xScale || !yScale) {
        console.error("X or Y axis scale could not be obtained");
        return null;
      }

      return (
        <>
          {data.map((entry, index) => {
            const prefix = isExternalAI ? 'ExternalAi' : '';
            const total = (entry[`confirmadas${prefix}`] || 0) +
              (entry[`traducidas${prefix}`] || 0) +
              (entry[`eliminadas${prefix}`] || 0);

            const baseX = xScale(entry.dia);
            const quarterWidth = xScale.bandwidth() / 4;

            const barX = showExternalAi
              ? baseX + (isExternalAI ? quarterWidth * 3 : quarterWidth)
              : baseX + xScale.bandwidth() / 2;
            const barY = yScale(total) ?? 0;

            return (
              <text
                key={index}
                x={barX}
                y={barY - 5}
                textAnchor="middle"
                fill="#000"
                fontSize={14}
              >
                {total}
              </text>
            );
          })}
        </>
      );
    };

    const parsedData = processData(data);

    return (
      <div style={{ width: "100%", height: 600, marginTop: "20px" }}>
        <ResponsiveContainer width="99%" height="99%" key={Math.random()}>
          <BarChart width={500} height={600} data={parsedData} margin={{ top: 20 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="dia" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="confirmadas"
              stackId="a"
              fill={hexToRGBA(barColors[0], 0.5)}
            />
            <Bar
              dataKey="traducidas"
              stackId="a"
              fill={hexToRGBA(barColors[1], 0.5)}
            />
            <Bar
              dataKey="eliminadas"
              stackId="a"
              fill={hexToRGBA(barColors[2], 0.5)}
            />
            <Bar
              hide={!showExternalAi}
              legendType="none"
              dataKey="confirmadasExternalAi"
              stackId="b"
              fill={hexToRGBA(barColors[3], 0.5)}
            />
            <Bar
              hide={!showExternalAi}
              legendType="none"
              dataKey="traducidasExternalAi"
              stackId="b"
              fill={hexToRGBA(barColors[4], 0.5)}
            />
            <Bar
              hide={!showExternalAi}
              legendType="none"
              dataKey="eliminadasExternalAi"
              stackId="b"
              fill={hexToRGBA(barColors[5], 0.5)}
            />
            {showExternalAi && <Customized component={(props) => renderTotal(props, parsedData, true)} />}
            <Customized component={(props) => renderTotal(props, parsedData, false)} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const displayExternalAiSwitch = (
    <ThemeProvider theme={theme}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={showExternalAi}
              onChange={(event) => setShowExternalAi(event.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Mostrar external ai"
        />
      </FormGroup>
    </ThemeProvider>
  );

  const displayExternalReadDeviceSwitch = (
    <ThemeProvider theme={theme}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={showExternalReadDevice}
              onChange={(event) =>
                setShowExternalReadDevice(event.target.checked)
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Mostrar external read device"
        />
      </FormGroup>
    </ThemeProvider>
  );

  const Loading = () => (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ padding: "20px" }}
    >
      <p style={{ marginTop: "10px", textAlign: "center" }}>
        <CircularProgress size={30} color="primary" /> Cargando...
      </p>
    </Box>
  );

  return (
    <Page title={"Gráfico lecturas"} primaryColor="black">
      {measurements ? (
        <>
          <Box
            component="section"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            {displayExternalAiSwitch}
            {displayExternalReadDeviceSwitch}
          </Box>
          <Box component="section">
            {chart(measurements)}
          </Box>
        </>
      ) : (
        <Box
          component="section"
          sx={{
            marginTop: "20px",
            p: 2,
            border: "1px dashed red",
          }}
        >
          {/* <p style={{ textAlign: "center" }}>Selecciona un dispositivo</p> */}
          <Loading />
        </Box>
      )}
    </Page>
  );
};

export { MeasurementsGraphic };
