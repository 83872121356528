import CompanyDataRow from "./CompanyDataRow";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { sortText } from "../../../App/sortFunctions";
import { CompanyActionsPopover } from "./CompanyActionsPopover";
import { GridColDef } from "@mui/x-data-grid";

export interface PopoverConfig {
  onDelete: (company: CompanyDataRow) => void;
  onEdit: (company: CompanyDataRow) => void;
  onLoginAs: (companyId: number) => void;
  onAddAdmin: (company: CompanyDataRow) => void;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => void;
  onClose: () => void;
  onDashboardSelect: (companyId: number) => void;
  onDevicesSelect: (companyId: number) => void;
  onReadDevicesSelect: (companyId: number) => void;
  anchorEl: HTMLElement | null;
  popoverId: string;
}

export const CompanyGridColumns = (
  popoverConf?: PopoverConfig
): GridColDef<CompanyDataRow>[] => {
  const openPopover = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    if (popoverConf?.onClick) {
      popoverConf?.onClick(event, item);
    }
  };

  const gridColumns: GridColDef<CompanyDataRow>[] = [
    {
      field: "id",
      headerName: "ID",
      width: 50,
      align: "left",
      valueGetter: (value: any, row: CompanyDataRow) => row.id,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: CompanyDataRow }) => {
        return params.row.id;
      },
    },
    {
      field: "nombre",
      headerName: "Nombre",
      width: 250,
      align: "left",
      valueGetter: (value: any, row: CompanyDataRow) => row.name,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: CompanyDataRow }) => {
        return params.row.name;
      },
    },
    {
      field: "costDiff",
      headerName: "Gasto/Coste Máximo",
      width: 160,
      align: "center",
      valueGetter: (value: any, row: CompanyDataRow) => row.actualCost,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: CompanyDataRow }) => {
        return `${params.row.actualCost} / ${params.row.maxCost} $`;
      },
    },
    {
      field: "countSms",
      headerName: "Contador SMS",
      width: 120,
      align: "center",
      valueGetter: (value: any, row: CompanyDataRow) => row.countSms,
      sortComparator: (v1: string, v2: string) => sortText(v1, v2),
      renderCell: (params: { row: CompanyDataRow }) => {
        return `${params.row.countSms}`;
      },
    },
    {
      field: "actions",
      headerName: "Acciones",
      filterable: false,
      sortable: false,
      renderCell: (params: { row: CompanyDataRow }) => {
        return (
          <>
            <IconButton onClick={(event) => openPopover(event, params.row)}>
              <MoreVertIcon />
            </IconButton>
            <CompanyActionsPopover
              row={params.row}
              popoverConf={popoverConf}
            ></CompanyActionsPopover>
          </>
        );
      },
    },
  ];

  return gridColumns;
};
