import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { formattedDate } from "../../utils";
import { IMeasurements } from "./MeasurementsDayCompanyGrid";

export const MeasurementsDayCompanyGridColumns = (): GridColDef<IMeasurements>[] => {
  const gridColumns: GridColDef<IMeasurements>[] = [
    {
      field: 'day',
      headerName: 'Fecha',
      width: 100,
      align: 'left',
      valueGetter: (value: any, row: IMeasurements) => row.day,
      renderCell: (params: GridCellParams<IMeasurements>) => {
        return <p>{formattedDate(params.row.day)}</p>;
      }
    },
    {
      field: 'company',
      headerName: 'Centro médico',
      width: 100,
      align: 'left',
      valueGetter:(value: any, row: IMeasurements) => row.company,
      renderCell: (params: GridCellParams<IMeasurements>) => {
        return <p>{params.row.company}</p>;
      }
    },
    {
      field: 'count',
      headerName: 'Cantidad lecturas',
      width: 150,
      align: 'center',
      valueGetter: (value: any, row: IMeasurements) => row.count,
      renderCell: (params: GridCellParams<IMeasurements>) => {
        return <p>{params.row.count}</p>;
      }
    }
  ];

  return gridColumns;
};
