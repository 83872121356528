import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { Page } from "../../Page";
import { ActiveUsersGridColumns } from "./ActiveUsersGridColumns";
import { GetActiveUsersByDayResponseDto } from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";
import { GridColDef } from "@mui/x-data-grid";

export interface IActiveUsers {
  day: string;
  count: number;
  id: number;
}

export interface IActiveUsersGrid {
  activeUsersByDay?: GetActiveUsersByDayResponseDto[];
}

const ActiveUsersGrid = ({
  activeUsersByDay,
}: IActiveUsersGrid): React.ReactElement => {
  const activeUsersDataRows: IActiveUsers[] | undefined = activeUsersByDay?.map(
    (activeUsers: GetActiveUsersByDayResponseDto, index: number) => ({
      id: index,
      day: activeUsers.day,
      count: activeUsers.count,
    })
  );

  const activeUsersColumns: GridColDef<IActiveUsers>[] =
    ActiveUsersGridColumns();

  return (
    <Page title={"Usuarios activos"} primaryColor="black">
      <FlexLayoutGrid
        columns={activeUsersColumns as any}
        rows={activeUsersDataRows ? activeUsersDataRows : []}
      ></FlexLayoutGrid>
    </Page>
  );
};

export { ActiveUsersGrid };
