import { GridSortItem } from "@mui/x-data-grid";
import { useEffect, useState, useCallback } from "react";
import { DataRange } from "@doctomatic/sdk/build/utils/query/filter.interface";
import { useApi } from "@doctomatic/sdk/build/Api";
import { FilterOption } from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";
import { Mode } from "@doctomatic/sdk/build/dto/Images";
import { IUseCmsReadDeviceViewer } from "@doctomatic/sdk/build/modules/CmsReadDeviceViewer";
import moment from "moment";
import { Page } from "../Page";
import {
  CircularProgress,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { DateRangePicker } from "@doctomatic/components-react/build/Graphs/DateRangePicker";
import { debounce } from "lodash";
import { SelectDevice } from "../Dashboard/Measurements/selectDevice";
import { ReadDeviceViewerList } from "./ReadDeviceViewerList";
import Switch from "@material-ui/core/Switch";
import { processError } from "../../../App/errorToast";
import { SelectCompany } from "../Dashboard/selectCompany";
import { useLocation } from "react-router-dom";
import { SelectStatus } from "./selectStatus";
import { read } from "fs";
import { BACKGROUND_RED } from "@doctomatic/constants/dist/constants";
import RestoreIcon from "@mui/icons-material/Restore";
import { SelectDate } from "../../DatePicker/selectDate";

const INITIAL_PAGINATION = { page: 1, limit: 10, sortBy: [] };

interface LocationProps {
  state: unknown;
  companyToSelect: number;
  dateRangeToSelect: DataRange<string>;
  deviceToSelect: number;
  readDeviceRangeToSelect: DataRange<number>;
  takeByIdToSelect: number;
  patientIdToSelect: number;
}

const ReadDeviceViewer = (): React.ReactElement => {
  //--------------------------------------------------------------------------------------//
  //                                  State declarations                                  //
  //--------------------------------------------------------------------------------------//
  const location = useLocation();
  const state = location.state as LocationProps;
  const params = new URLSearchParams(location.search);

  const [pageSize, setPageSize] = useState<number>(INITIAL_PAGINATION.limit);
  const [page, setPage] = useState<number>(INITIAL_PAGINATION.page);
  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<any>(INITIAL_PAGINATION);
  const [sortBy, setSortBy] = useState<GridSortItem[]>([]);

  const [dateRange, setDateRange] = useState<DataRange<string>>(); // Query date
  const dateRangeToSelect = state?.dateRangeToSelect;
  const defaultDateRange: [Date | null, Date | null] = dateRangeToSelect
    ? [new Date(dateRangeToSelect.start), new Date(dateRangeToSelect.end)]
    : [null, null];
  const readDeviceRangeToSelect = state?.readDeviceRangeToSelect;
  const [readDeviceRange, setReadDeviceRange] = useState<
    DataRange<number> | undefined
  >(readDeviceRangeToSelect);

  const deviceToSelect = state?.deviceToSelect ?? undefined;
  const takeByIdToSelect = state?.takeByIdToSelect ?? undefined;
  const patientIdToSelect = state?.patientIdToSelect ?? undefined;
  const companyToSelect = state?.companyToSelect ?? undefined;

  const [translated, setTranslated] = useState<FilterOption>(
    FilterOption.INCLUDE
  );
  const [removed, setRemoved] = useState<FilterOption>(FilterOption.INCLUDE);
  const [pendingTranslation, setPendingTranslation] = useState<FilterOption>(
    FilterOption.INCLUDE
  );
  const [pendingConfirm, setPendingConfirm] = useState<FilterOption>(
    FilterOption.INCLUDE
  );

  const [confirmed, setConfirmed] = useState<FilterOption>(
    FilterOption.INCLUDE
  );

  const [externalAiResponse, setExternalAiResponse] = useState<boolean>(false);
  const [internalAiResponse, setInternalAiResponse] = useState<boolean>(false);

  const getMode = (mode: string): string | undefined => {
    if (mode === "Todos") {
      return undefined;
    }
    return mode;
  };

  const [companyParam, setCompanyParam] = useState<string | null>(
    !state?.companyToSelect ? params.get("company") : null
  );

  // Filtros
  const filterWithSelect = {
    date: defaultDateRange,
    companyId: companyToSelect ?? 0,
    device: deviceToSelect,
    readDeviceStart: readDeviceRange?.start,
    readDeviceEnd: readDeviceRange?.end,
    statusName: undefined,
    mode: "Todos",
    extendedData: false,
    showExternal: false,
    patientId: patientIdToSelect,
    takeById: takeByIdToSelect,
  };
  const filterDefault = {
    date: [null, null],
    companyId: 0,
    device: undefined,
    readDeviceStart: "",
    readDeviceEnd: "",
    statusName: undefined,
    mode: "Todos",
    extendedData: false,
    showExternal: false,
    patientId: undefined,
    takeById: undefined,
  };

  const getInitialFilters = () => {
    if (
      companyToSelect ||
      deviceToSelect ||
      readDeviceRangeToSelect ||
      dateRangeToSelect
    ) {
      return filterWithSelect;
    }
    const savedFilters = localStorage.getItem("filtrosReadDevice");
    if (savedFilters) {
      return JSON.parse(savedFilters);
    }
    return filterDefault;
  };

  const [filters, setFilters] = useState(getInitialFilters);

  const {
    date,
    companyId,
    device,
    readDeviceStart,
    readDeviceEnd,
    statusName,
    mode,
    extendedData,
    showExternal,
    patientId,
    takeById,
  } = filters;

  useEffect(() => {
    localStorage.setItem("filtrosReadDevice", JSON.stringify(filters));
  }, [filters]);

  const updateFilter = (
    key: string,
    value: string | number | [Date | null, Date | null] | boolean | string[]
  ) => {
    if (key === "date") {
      if (
        Array.isArray(value) &&
        value.length === 2 &&
        ((value[0] && value[1]) || (value[0] === null && value[1] === null))
      ) {
        setFilters((prev: Object) => ({ ...prev, [key]: value }));
      }
    } else {
      setFilters((prev: Object) => ({ ...prev, [key]: value }));
    }
  };

  const restoreFilters = () => {
    setFilters(filterDefault);
  };

  useEffect(() => {
    const statusMap = {
      translated: setTranslated,
      removed: setRemoved,
      pendingTranslation: setPendingTranslation,
      pendingConfirm: setPendingConfirm,
      confirmed: setConfirmed,
    };

    if (statusName === undefined) {
      Object.keys(statusMap).forEach((key) => {
        const setStatus = statusMap[key as keyof typeof statusMap];
        setStatus(FilterOption.INCLUDE);
      });
    } else {
      Object.keys(statusMap).forEach((key) => {
        const setStatus = statusMap[key as keyof typeof statusMap];
        setStatus(
          statusName?.includes(key)
            ? FilterOption.INCLUDE
            : FilterOption.EXCLUDE
        );
      });
    }
  }, [statusName]);

  useEffect(() => {
    setExternalAiResponse(extendedData);
    setInternalAiResponse(extendedData);
  }, [extendedData]);

  //--------------------------------------------------------------------------------------//
  //                                      Api calls                                       //
  //--------------------------------------------------------------------------------------//

  const { useCmsReadDeviceViewer, useSankeyGraph, logout } = useApi();

  const { getData } = useSankeyGraph(processError(logout, history));
  const {
    response: readDevicesResponse,
    getDebugImage,
    getImageAndMeasurements,
    getImage,
  }: IUseCmsReadDeviceViewer = useCmsReadDeviceViewer(
    pagination,
    +companyId,
    showExternal,
    dateRange,
    readDeviceRange,
    device,
    translated,
    removed,
    getMode(mode),
    pendingTranslation,
    pendingConfirm,
    confirmed,
    extendedData,
    true,
    +takeById,
    +patientId
  );

  const readDevices = readDevicesResponse?.data;
  const readDevicesPaginationInfo = readDevicesResponse?.meta;

  const readDevicesList =
    // loading || !readDevices ? (
    loading ? (
      <CircularProgress
        style={{
          justifyContent: "center",
          display: "flex",
          width: "100%",
          marginTop: 50,
        }}
      />
    ) : (
      readDevices && (
        <ReadDeviceViewerList
          readDevices={readDevices}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          paginationInfo={readDevicesPaginationInfo}
          sortBy={sortBy}
          onSortChange={(newSort) => setSortBy(newSort as GridSortItem[])}
          getDebugImage={getDebugImage}
          getImageAndMeasurements={getImageAndMeasurements}
          extendedData={extendedData}
          externalAiResponse={externalAiResponse}
          internalAiResponse={internalAiResponse}
          getImage={getImage}
          getSankeyData={getData}
          showExternal={showExternal}
        />
      )
    );

  //--------------------------------------------------------------------------------------//
  //                               Use effect declarations                                //
  //--------------------------------------------------------------------------------------//

  useEffect(() => {
    setLoading(true);
    setPagination({
      page: page,
      limit: pageSize,
      sortBy: sortBy,
    });
  }, [page, pageSize, sortBy]);

  useEffect(() => {
    setLoading(false);
  }, [readDevices]);

  useEffect(() => {
    if (date[0] && date[1]) {
      setDateRange({
        start: moment(date[0])
          .startOf("day")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss"),
        end: moment(date[1]).endOf("day").utc().format("YYYY-MM-DD HH:mm:ss"),
      });
    } else {
      setDateRange(undefined);
    }
  }, [date]);

  useEffect(() => {
    if (readDeviceStart && readDeviceEnd) {
      setReadDeviceRange({ start: readDeviceStart, end: readDeviceEnd });
    } else {
      setReadDeviceRange(undefined);
    }
  }, [readDeviceStart, readDeviceEnd]);

  useEffect(() => {
    setPage(INITIAL_PAGINATION.page);
  }, [
    dateRange,
    date,
    readDeviceRange,
    device,
    translated,
    removed,
    pendingTranslation,
    pendingConfirm,
    confirmed,
  ]);

  // const debouncedSetSearch = useCallback(debounce(setReadDeviceEnd, 1000), []);

  // const handleDeviceEnd = (readDeviceEnd: number) => {
  //   debouncedSetSearch(readDeviceEnd);
  // };

  return (
    <Page title={"Read device viewer"}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ justifyContent: "center", marginBottom: 20 }}
      >
        <Grid item>
          <SelectDate
            key={JSON.stringify(date)}
            date={date.map((date: string | null) =>
              date ? new Date(date) : null
            )}
            setDate={(value) => updateFilter("date", value)}
          />
        </Grid>
        <Grid item>
          <TextField
            id="fromReadDevice"
            label="Desde readDeviceId"
            type="number"
            value={readDeviceStart}
            onChange={(event) =>
              updateFilter(
                "readDeviceStart",
                event.target.value as unknown as number
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="toReadDevice"
            label="Hasta readDeviceId"
            type="number"
            value={readDeviceEnd}
            onChange={(event) =>
              updateFilter(
                "readDeviceEnd",
                event.target.value as unknown as number
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="patientId"
            label="patientId"
            type="number"
            value={patientId || ""}
            onChange={(event) =>
              updateFilter("patientId", Number(event.target.value))
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            id="takeById"
            label="takeById"
            type="number"
            value={takeById || ""}
            onChange={(event) =>
              updateFilter("takeById", Number(event.target.value))
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item>
          <SelectDevice
            deviceId={device}
            setDeviceId={(value) => updateFilter("device", value)}
            whiteBg={false}
          />
        </Grid>
        <Grid item>
          <SelectCompany
            companyId={companyId}
            companyParam={companyParam}
            setCompanyId={(value) => updateFilter("companyId", value)}
          />
        </Grid>
        <Grid item>
          <SelectStatus
            statusName={statusName}
            setStatusName={(value) => updateFilter("statusName", value)}
          />
        </Grid>
        <Grid item>
          <InputLabel id="select-mode">Modo</InputLabel>
          <Select
            labelId="select-mode"
            id="select-mode"
            label="Modo"
            value={mode}
            onChange={(event) =>
              updateFilter("mode", event.target.value as string)
            }
            style={{ width: 100 }}
          >
            <MenuItem key="all" value={"Todos"}>
              {"Todos"}
            </MenuItem>
            <MenuItem key="7s" value={Mode.SEVENSEGMENT}>
              {"Solo 7s"}
            </MenuItem>
            <MenuItem key="normal" value={Mode.NORMAL}>
              {"Solo normal"}
            </MenuItem>
          </Select>
        </Grid>
        <Grid item>
          <InputLabel id="display-extended-data">Mostrar más info</InputLabel>
          <Switch
            checked={extendedData}
            onChange={(event) =>
              updateFilter("extendedData", event.target.checked)
            }
            inputProps={{ "aria-label": "controlled" }}
          />
        </Grid>
        <Grid item>
          <InputLabel id="display-extended-data">
            Mostrar lecturas externas
          </InputLabel>
          <Switch
            checked={showExternal}
            onChange={(event) => {
              updateFilter("showExternal", event.target.checked);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Grid>
        <Grid item style={{ marginLeft: 30 }}>
          <FormGroup>
            <div
              onClick={restoreFilters} // Ejecuta la función al hacer clic en cualquier parte
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <RestoreIcon
                aria-label="Restaurar filtros"
                style={{ color: BACKGROUND_RED, marginRight: 8 }}
              />
              <span style={{ color: BACKGROUND_RED }}>Restaurar filtros</span>
            </div>
          </FormGroup>
        </Grid>
      </Grid>
      {readDevicesList}
    </Page>
  );
};

export { ReadDeviceViewer };
