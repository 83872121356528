import { GridColDef } from "@mui/x-data-grid";
import { formattedDate } from "../../utils";
import { ITranslations } from "./TranslationsGrid";

export const TranslationsGridColumns = (): GridColDef<ITranslations>[] => {
  return [
    {
      field: "updatedAt",
      headerName: "Fecha",
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => row.day,
      renderCell: (params: { row: any }) => {
        return <p>{formattedDate(params.row.day)}</p>;
      },
    },
    {
      field: "countUsers",
      headerName: "Cantidad traducciones",
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => row.count,
      renderCell: (params: { row: any }) => {
        return <p>{params.row.count}</p>;
      },
    },
  ];
};
