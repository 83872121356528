import { GridColDef } from "@mui/x-data-grid";
import { IUsersByRole } from "./UsersByRoleGrid";

export const UsersByRoleGridColumns = (): GridColDef<IUsersByRole>[] => {
  return [
    {
      field: "role",
      headerName: "Rol",
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => row.name,
      renderCell: (params) => {
        return <p>{params.row.role}</p>;
      },
    },
    {
      field: "countUsers",
      headerName: "Cantidad usuarios",
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => row.group,
      renderCell: (params) => {
        return <p>{params.row.countUsers}</p>;
      },
    },
  ];
};
