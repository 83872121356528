var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import React from "react";
import { styles } from "./styles";
import { ImageDisplay } from "./ImageDisplay";
import DownloadIcon from "@mui/icons-material/Download";
import { DevicesSelect } from "../Graphs/GraphAlert/DevicesSelect";
export var ImageTranslateModal = function (_a) {
    var imageUrl = _a.imageUrl, altText = _a.altText, open = _a.open, handleClose = _a.handleClose, t = _a.t, error = _a.error, handleError = _a.handleError, measurements = _a.measurements, onSave = _a.onSave, editPermissions = _a.editPermissions, deletePermissions = _a.deletePermissions, onDeleteMany = _a.onDeleteMany, onChange = _a.onChange, devices = _a.devices, device = _a.device, setDevice = _a.setDevice, onSaveText = _a.onSaveText, onDeleteText = _a.onDeleteText, onCloseText = _a.onCloseText, translation = _a.translation, _b = _a.isModal, isModal = _b === void 0 ? true : _b, currentLanguage = _a.currentLanguage, clearMeasurements = _a.clearMeasurements;
    var handleOnSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onSave(measurements)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDownload = function () {
        var link = document.createElement("a");
        link.href = imageUrl;
        link.download = "imagen.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    var handleSetDevice = function (id) {
        var foundDevice = devices.find(function (x) { return x.id === id; });
        setDevice(foundDevice);
        clearMeasurements();
    };
    var devicesSelector = (React.createElement("div", { style: { marginTop: "10px" } },
        React.createElement(DevicesSelect, { deviceId: device.id, setDeviceId: handleSetDevice, devices: devices, t: t })));
    function getSignName(currentLanguage, sign, t) {
        var signName = sign.translations
            ? sign.translations.signName
                .map(function (data) {
                if (data.lang === currentLanguage) {
                    return data.text;
                }
                return null;
            })
                .filter(function (text) { return text !== null; })[0] || t(sign.name)
            : t(sign.name);
        return signName;
    }
    function getDeviceName(currentLanguage, device, t) {
        var deviceName = device.translations
            ? device.translations.deviceName
                .map(function (data) {
                if (data.lang === currentLanguage) {
                    return data.text;
                }
                return null;
            })
                .filter(function (text) { return text !== null; })[0] || t(device.name)
            : t(device.name);
        return deviceName;
    }
    var imageTranslateContainer = (React.createElement(Box, { sx: isModal ? styles.containerModal : styles.container },
        React.createElement(ImageDisplay, { imageUrl: imageUrl, altText: altText, t: t, error: error, handleError: handleError }),
        React.createElement(Box, { m: 2, style: {
                maxHeight: "calc(100vh - 650px)",
                overflowY: "auto",
            } },
            devicesSelector,
            React.createElement(Box, { sx: styles.inputGrid }, device &&
                device.signs &&
                device.signs.map(function (sign, index) {
                    var measurement = measurements.find(function (m) { return m.signId === sign.id; });
                    return (React.createElement(Box, { sx: styles.centeredItem, key: "key-".concat(index) },
                        React.createElement(TextField, { key: index, disabled: !editPermissions, margin: "dense", variant: "outlined", name: getSignName(currentLanguage, sign, t), label: "".concat(getSignName(currentLanguage, sign, t), " (").concat(t(sign.unit), ")"), type: "number", value: measurement ? measurement.value : "", fullWidth: true, onChange: function (event) { return onChange(event, sign.id); }, autoFocus: index === 0 })));
                })),
            React.createElement(Box, { m: 2, style: styles.bottomButtons },
                editPermissions && (React.createElement(Button, { sx: styles.botBtn, variant: "contained", size: "small", onClick: handleOnSave, disabled: measurements.length === 0 }, onSaveText !== null && onSaveText !== void 0 ? onSaveText : t("SaveMeasurements"))),
                deletePermissions && (React.createElement(Button, { sx: styles.botBtn, variant: "contained", size: "small", color: "secondary", onClick: onDeleteMany, disabled: translation ? false : measurements.length === 0 }, onDeleteText !== null && onDeleteText !== void 0 ? onDeleteText : t("DeleteMeasurements"))),
                isModal && (React.createElement(Button, { sx: styles.botBtn, variant: "contained", color: "inherit", size: "small", onClick: handleClose }, onCloseText !== null && onCloseText !== void 0 ? onCloseText : t("CloseImage"))),
                React.createElement(Button, { sx: styles.dwnBtn, variant: "contained", color: "inherit", size: "small", onClick: handleDownload, title: t("DownloadButton"), disabled: imageUrl.length === 0 || error },
                    React.createElement(DownloadIcon, null))))));
    return (React.createElement("div", null, isModal ? (React.createElement(Modal, { open: open, onClose: handleClose, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, imageTranslateContainer)) : (React.createElement(React.Fragment, null, imageTranslateContainer))));
};
