import { FormControl, InputLabel } from "@material-ui/core";
import { DateRangePicker } from "@doctomatic/components-react/build/Graphs/DateRangePicker";
import { useEffect, useMemo, useState } from "react";

interface DateSelectProp {
  date: [Date | null, Date | null];
  setDate: (date: [Date | null, Date | null]) => void;
  key?: string;
}

const SelectDate = ({ date, setDate, key }: DateSelectProp): React.ReactElement => {
  return (
    <FormControl variant="outlined" style={{ minWidth: 200 }}>
      <InputLabel id="fecha">{"Fecha"}</InputLabel>
      <DateRangePicker key={key} dateRangePicked={date} setDateRange={setDate} />
    </FormControl>
  );
};

export { SelectDate };
