import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { Page } from "../../Page";
import { MeasurementsGridColumns } from "./MeasurementsGridColumns";
import { GetReadDeviceByDayResponseDto } from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";
import { GridColDef } from "@mui/x-data-grid";

export interface IMeasurements {
  id: number;
  day: string;
  device: string;
  count: number;
  countTranslateImage: number;
  countDeleteImage: number;
}

export interface IMeasurementsGrid {
  measurements: GetReadDeviceByDayResponseDto[];
}

const MeasurementsGrid = ({
  measurements,
}: IMeasurementsGrid): React.ReactElement => {
  const measurementsDataRows: IMeasurements[] = measurements?.map(
    (
      measurements: GetReadDeviceByDayResponseDto, // better option interface sdk.
      index: number
    ) => ({
      id: index,
      day: measurements.day,
      device: measurements.device,
      count: measurements.count,
      countTranslateImage: measurements.countTranslateImage,
      countDeleteImage: measurements.countDeleteImage,
    })
  );

  const measurementsColumns: GridColDef<IMeasurements>[] =
    MeasurementsGridColumns();

  return (
    <Page title={"Lecturas"} primaryColor="black">
      <FlexLayoutGrid
        columns={measurementsColumns as any}
        rows={measurementsDataRows ? measurementsDataRows : []}
      ></FlexLayoutGrid>
    </Page>
  );
};

export { MeasurementsGrid };
