import { GridColDef } from "@mui/x-data-grid";
import { formattedDate } from "../../utils";
import { IActiveUsers } from "./ActiveUsersGrid";

export const ActiveUsersGridColumns = (): GridColDef<IActiveUsers>[] => {
  return [
    {
      field: "updatedAt",
      headerName: "Fecha",
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => row.name,
      renderCell: (params: { row: any }) => {
        return <p>{formattedDate(params.row.day)}</p>;
      },
    },
    {
      field: "countUsers",
      headerName: "Cantidad usuarios",
      width: 200,
      align: "left",
      valueGetter: (value: any, row: any) => row.group,
      renderCell: (params: { row: any }) => {
        return <p>{params.row.count}</p>;
      },
    },
  ];
};
