import React from "react";
import { useApi } from "@doctomatic/sdk/build/Api";
import { GetCompanyResponseDto } from "@doctomatic/sdk/build/dto/Company";
import { processError } from "../../../App/errorToast";
import { useNavigate } from "react-router-dom";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { colors } from "../../Theme/Theme";
import { CompanyGridColumns } from "./CompanyGridColumns";
import CompanyDataRow from "./CompanyDataRow";

interface Props {
  onDelete: (company: GetCompanyResponseDto) => void;
  onEdit: (company: GetCompanyResponseDto) => void;
  onLoginAs: (companyId: number) => void;
  onAddAdmin: (company: GetCompanyResponseDto) => void;
}

const CompanyList = ({
  onDelete,
  onEdit,
  onLoginAs,
  onAddAdmin,
}: Props): React.ReactElement => {
  const { useCompanies, logout } = useApi();
  const history = useNavigate();
  const { response: companiesResponse } = useCompanies(
    true,
    processError(logout, history)
  );
  const companies = companiesResponse?.data;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [popoverId, setPopoverId] = React.useState<string>("");

  const onClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    item: any
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverId(`m${item.id}`);
  };
  const onClose = () => {
    setAnchorEl(null);
    setPopoverId("");
  };

  const onDashboardSelect = (companyId: number) =>
    history("/dashboard", {
      state: { companyToSelect: companyId },
    });

  const onDevicesSelect = (companyId: number) =>
    history("/devices", {
      state: { companyToSelect: companyId },
    });

  const onReadDevicesSelect = (companyId: number) =>
    history("/read_devices_viewer", {
      state: { companyToSelect: companyId },
    });

  const companyGridColumns = CompanyGridColumns({
    onClick,
    onClose,
    anchorEl,
    popoverId,
    onEdit,
    onDelete,
    onLoginAs,
    onAddAdmin,
    onDashboardSelect,
    onDevicesSelect,
    onReadDevicesSelect,
  });

  const setRowClassName = (params: any): string => {
    if (params.row.deletedAt) return "super-app-theme--Rejected";
    return "";
  };

  const companiesDataRow: CompanyDataRow[] =
    companies?.map((company: GetCompanyResponseDto) => {
      const companyDataRow: CompanyDataRow = {
        id: company.id,
        fee: company.fee,
        tax: company.tax,
        stripeAccount: company.stripeAccount,
        stripePriceId: company.stripePriceId,
        primary: company.primary,
        secondary: company.secondary,
        logo: company.logo,
        name: company.name,
        trialCode: company.trialCode,
        trialDays: company.trialDays,
        taxId: company.taxId,
        maxPatientsWithoutSubscription: company.maxPatientsWithoutSubscription,
        showExternalId: company.showExternalId,
        forceFrequenciesCreation: company.forceFrequenciesCreation,
        maxCost: company.maxCost,
        actualCost: company.actualCost,
        countSms: company.countSms,
        onBoardingReq: company.onBoardingReq,
        messengerChannel: company.messengerChannel,
        readDeviceDescription: company.readDeviceDescription,
      };
      return companyDataRow;
    }) || [];

  return (
    <FlexLayoutGrid
      columns={companyGridColumns as any}
      rows={companiesDataRow}
      setRowClassName={setRowClassName}
      // TODO: add data grid style prop to FlexLayoutGrid
      sx={{
        height: "78.5vh",
        maxHeight: "100vh",
        overflowY: "auto",
        backgroundColor: colors.grey,
      }}
    ></FlexLayoutGrid>
  );
};

export { CompanyList };
