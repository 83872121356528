import React, { useState } from "react";
import { GetValidateImageResponseDto } from "@doctomatic/sdk/build/dto/ImagesValidator";
import { GridRowClassNameParams, GridSortModel } from "@mui/x-data-grid";
import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { useTranslation } from "react-i18next";
import { ImageValidatorGridColumns } from "./ImageValidatorGridColumns";
import ImageValidateDataRow from "./ImageValidatorDataRow";
import { DoctomaticStylingRowsGrid } from "./utils";
import { ReadDeviceValidate, ImageEditModal } from "./ImageValidatorModal";
import { SignBasicDto } from "@doctomatic/sdk/src/dto/Sign/Signs";
import {
  UpdateMeasurementRequestDto,
  UpdateMeasurementResponseDto,
} from "@doctomatic/sdk/build/dto/Measurements/Measurements";
import { toast } from "react-toastify";
import { useLoading } from "../../Loading/Loading";
import { ResponseApi } from "@doctomatic/sdk/build/global";
import { Mode } from "@doctomatic/sdk/build/dto/Images";

export interface Measurement {
  id?: number;
  signId?: number;
  value?: number;
  createdAt?: string;
  readDeviceId?: number;
}

export interface BasicDeviceDto {
  id: number;
  name: string;
  typeName: string;
  form?: string;
  translations?: string;
}

interface Props {
  images: GetValidateImageResponseDto[];
  onPageChange: (newPageNumber: number) => void;
  onPageSizeChange: (newPageSize: number) => void;
  paginationInfo?: any;
  updateReadDeviceValidate: (
    readDeviceId: number,
    readDeviceValidate: ReadDeviceValidate[]
  ) => Promise<ResponseApi<void>>;
  updateMeasurements: (
    measurements: UpdateMeasurementRequestDto[]
  ) => Promise<ResponseApi<UpdateMeasurementResponseDto[]>>;
  updateMode: (readDeviceId: number, mode: Mode) => Promise<ResponseApi<void>>;
  mutate: () => void;
  sortBy?: GridSortModel;
  onSortChange?: (newSort: GridSortModel) => void;
}

const ImageValidatorList = ({
  images,
  onPageChange,
  onPageSizeChange,
  paginationInfo,
  updateReadDeviceValidate,
  updateMeasurements,
  updateMode,
  mutate,
  sortBy,
  onSortChange,
}: Props): React.ReactElement => {
  const { i18n, t } = useTranslation();
  const { setIsLoading, isLoading } = useLoading();
  const [imageItem, setImageItem] = useState<string | null>(null);
  const [debugItem, setDebugItem] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [measurements, setMeasurements] = useState<Measurement[]>([]);
  const [signs, setSigns] = useState<SignBasicDto[]>([]);
  const [readDeviceValidate, setReadDeviceValidate] = useState<string>();
  const [mode, setMode] = useState<Mode>();
  const [readDeviceId, setReadDeviceId] = useState<number>(0);
  const [debug, setDebug] = useState<boolean>(false);

  const openImageModal = async (
    item: ImageValidateDataRow,
    debug?: boolean
  ) => {
    setReadDeviceId(item.id);
    setImageItem(item.key);
    setMeasurements(item.measurementsInRead);
    setReadDeviceValidate(item.readDeviceValidate);
    setMode(item.mode);
    setDebugItem(item.debugUrl);
    setSigns(item.signs);
    setDebug(debug ? debug : item.debug);
  };

  const imagesGridColumns = ImageValidatorGridColumns(openImageModal);

  const imagesDataRow: ImageValidateDataRow[] = images.map(
    (image: GetValidateImageResponseDto) => {
      const alertByValueDataRow = new ImageValidateDataRow(
        image.id,
        image.key,
        image.debugUrl,
        image.readDeviceValidate,
        image.additionalData,
        image.paki,
        image.mode,
        image.device,
        image.signs,
        image.measurementsInRead,
        image.created_at,
        false
      );
      return alertByValueDataRow;
    }
  );

  const handleError = () => {
    setError(true);
  };

  const onSaveMeasurement = async (measurements: Measurement[], mode: Mode) => {
    setIsLoading(true);
    try {
      let newsMeasurements: UpdateMeasurementRequestDto[] = [];
      measurements.forEach((measurement) => {
        let updateMeasurement = new UpdateMeasurementRequestDto();
        Object.assign(updateMeasurement, measurement);
        updateMeasurement.measurementId = measurement.id as number;
        newsMeasurements.push(updateMeasurement);
      });
      const updateResponse = await updateMeasurements(newsMeasurements);
      if (updateResponse.success === true) {
        toast.success("Medidas actualizadas correctamente");
      }
      mutate();
    } catch (err) {
      toast.error("Error actualizando las medidas");
    }

    try {
      updateMode(readDeviceId, mode);
    } catch (err) {
      toast.error("Error actualizando el modo");
    }
    setIsLoading(false);
  };

  const onUpdateReadDeviceValidate = async (
    readDeviceId: number,
    readDeviceValidate: ReadDeviceValidate[]
  ) => {
    setIsLoading(true);
    try {
      const update = await updateReadDeviceValidate(
        readDeviceId,
        readDeviceValidate
      );

      if (update.success === true) {
        toast.success("Validación actualizada correctamente");
        mutate();
        setError(false);
      }
    } catch (err) {
      toast.error("Error actualizando la validación");
    }
    setIsLoading(false);
  };

  const isFinishStatus = (status: string) => {
    return status[0] === "1" || status[2] === "1" || status[3] === "1";
  };

  const setRowClassName = (
    params: GridRowClassNameParams<ImageValidateDataRow>
  ): string => {
    if (
      params.row.readDeviceValidate &&
      isFinishStatus(params.row.readDeviceValidate)
    ) {
      return "validated-image";
    }

    return "";
  };

  const showEditImageModal = imageItem && debugItem && (
    <ImageEditModal
      debug={debug}
      signs={signs}
      measurements={measurements}
      readDeviceValidate={readDeviceValidate}
      mode={mode}
      onSave={onSaveMeasurement}
      onUpdate={onUpdateReadDeviceValidate}
      onClose={() => {
        setImageItem(null);
      }}
      readDeviceId={readDeviceId}
      imageUrl={imageItem}
      debugUrl={debugItem}
      open={imageItem !== null}
      handleClose={() => {
        setImageItem(null);
        setError(false);
      }}
      error={error}
      handleError={handleError}
      altText={""}
      t={t}
    />
  );

  return (
    <div
      style={{
        backgroundColor: "white",
      }}
    >
      <FlexLayoutGrid
        language={i18n.language}
        columns={imagesGridColumns as any}
        rows={imagesDataRow}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pagination={paginationInfo}
        isLoading={isLoading}
        setRowClassName={setRowClassName as any}
        sx={DoctomaticStylingRowsGrid() as any}
        sortBy={sortBy}
        onSortChange={onSortChange}
        height={"100%"}
        rowsPerPageOptions={[10, 25, 50, 100, 150, 200]}
      ></FlexLayoutGrid>
      {showEditImageModal}
    </div>
  );
};

export { ImageValidatorList };
