import { Page } from "../Page";
import { Grid } from "@mui/material";
import { ActiveUsersGrid } from "./ActiveUsers/ActiveUsersGrid";
import { TranslationsGrid } from "./Translations/TranslationsGrid";
import { MeasurementsGrid } from "./Measurements/MeasurementsGrid";
import { UsersByRoleGrid } from "./UsersByRole/UsersByRoleGrid";
import { useApi } from "@doctomatic/sdk/build/Api";
import { processError } from "../../../App/errorToast";
import { useNavigate, useLocation } from "react-router-dom";
import {
  GetActiveUsersByDayResponseDto,
  GetCountUsersByRoleResponseDto,
  GetReadDeviceByDayCompanyResponseDto,
  GetReadDeviceByDayResponseDto,
  GetSankeyGraphResponseData,
  GetSankeyTracesResponseData,
  GetTranslateByDayResponseDto,
} from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";
import { SelectCompany } from "./selectCompany";
import {
  Box,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Switch,
  TextField,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { SelectDate } from "../../DatePicker/selectDate";
import moment from "moment";
import { MeasurementsGraphic } from "./Measurements/MeasurementsGraphic";
import { SelectDevice } from "./Measurements/selectDevice";
import { SankeyGraph } from "./SankeyGraph/SankeyGraph";
import { DataRange } from "@doctomatic/sdk/build/utils/query/filter.interface";
import { debounce } from "lodash";
import { addDays } from "date-fns";
import VisibilityIcon from "@mui/icons-material/VisibilityOutlined";
import RestoreIcon from "@mui/icons-material/Restore";
import { BACKGROUND_RED } from "@doctomatic/constants/dist/constants";
import { MeasurementsDayCompanyGrid } from "./MeasurementsDayCompany/MeasurementsDayCompanyGrid";

interface LocationProps {
  state: unknown;
  companyToSelect: number;
}

const Dashboard = (): React.ReactElement => {
  const history = useNavigate();
  const location = useLocation();
  const state = location.state as LocationProps;
  const params = new URLSearchParams(location.search);

  const companyToSelect = state?.companyToSelect ?? undefined;

  const [companyParam, setCompanyParam] = useState<string | null>(
    !state?.companyToSelect ? params.get("company") : null
  );

  const [dateRange, setDateRange] = useState<DataRange<string>>(); // Query date
  const defaultStartDate = addDays(new Date(), -30);
  const defaultEndDate = new Date();

  const [timeZone, setTimeZone] = useState<number | undefined>();
  const [readDeviceRange, setReadDeviceRange] = useState<DataRange<number>>();

  const [traces, setTraces] = useState<GetSankeyTracesResponseData[]>([]);

  const [showExternalReadDevice, setShowExternalReadDevice] =
    useState<boolean>(false);

  // Filtros
  const filterDefault = {
    date: [defaultStartDate, defaultEndDate], // Valores por defecto
    companyId: companyToSelect ?? 0,
    deviceId: undefined,
    readDeviceStart: undefined,
    readDeviceEnd: undefined,
    patientId: undefined,
    takeById: undefined,
    patientsDeleted: false,
  };

  const getInitialFilters = () => {
    const savedFilters = localStorage.getItem("filtrosDashboard");
    if (savedFilters) {
      return JSON.parse(savedFilters);
    }
    return filterDefault;
  };

  const [filters, setFilters] = useState(getInitialFilters);

  const {
    date,
    companyId,
    deviceId,
    readDeviceStart,
    readDeviceEnd,
    patientId,
    takeById,
    patientsDeleted,
  } = filters;

  useEffect(() => {
    localStorage.setItem("filtrosDashboard", JSON.stringify(filters));
  }, [filters]);

  const updateFilter = (
    key: string,
    value: string | number | [Date | null, Date | null] | boolean
  ) => {
    if (key === "date") {
      if (
        Array.isArray(value) &&
        value.length === 2 &&
        ((value[0] && value[1]) || (value[0] === null && value[1] === null))
      ) {
        setFilters((prev: Object) => ({ ...prev, [key]: value }));
      }
    } else {
      setFilters((prev: Object) => ({ ...prev, [key]: value }));
    }
  };

  const restoreFilters = () => {
    setFilters(filterDefault);
  };

  const { useCmsDashboard, useSankeyGraph, logout } = useApi();
  const {
    response: countAllUsers,
    countUsersByRole,
    activeUsersByDay,
    readDeviceByDay,
    translateByDay,
    readDeviceByDayCompany,
  } = useCmsDashboard(
    +companyId,
    dateRange?.start,
    dateRange?.end,
    deviceId,
    timeZone,
    true,
    patientsDeleted,
    showExternalReadDevice,
    readDeviceRange?.start,
    readDeviceRange?.end,
    processError(logout, history)
  );

  const { getData } = useSankeyGraph(processError(logout, history));
  const AllUsers = countAllUsers?.data?.totalCount;
  const usersByRole = countUsersByRole?.response?.data;
  const activeUsers = activeUsersByDay?.response?.data;
  const measurements = readDeviceByDay?.response?.data;
  const translations = translateByDay?.response?.data;
  const measurementsDayCampany = readDeviceByDayCompany?.response?.data;

  useEffect(() => {
    if (date[0] && date[1]) {
      setDateRange({
        start: moment(date[0]).startOf("day").format("YYYY-MM-DD HH:mm:ss"),
        end: moment(date[1]).endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      });
    } else {
      setDateRange(undefined);
    }
  }, [date]);

  // get time zone
  useEffect(() => {
    const timeZoneOffsetHours = moment().utcOffset() / 60;
    setTimeZone(timeZoneOffsetHours);
  }, []);

  useEffect(() => {
    const fetchSankeyData = async () => {
      try {
        const response = await getData(
          +companyId,
          deviceId,
          timeZone,
          takeById,
          patientId,
          dateRange,
          readDeviceRange
        );
        if (response.data) {
          setTraces(response.data.traces);
        }
      } catch (err: any) {}
    };

    fetchSankeyData();
  }, [
    companyId,
    deviceId,
    timeZone,
    takeById,
    patientId,
    dateRange,
    readDeviceRange,
  ]);

  useEffect(() => {
    if (readDeviceStart && readDeviceEnd) {
      setReadDeviceRange({ start: readDeviceStart, end: readDeviceEnd });
    } else {
      setReadDeviceRange(undefined);
    }
  }, [readDeviceStart, readDeviceEnd]);

  // const debouncedSetSearch = useCallback(debounce(setReadDeviceEnd, 1000), []);

  // const handleDeviceEnd = (readDeviceEnd: number) => {
  //   debouncedSetSearch(readDeviceEnd);
  // };

  const handleRedirect = () =>
    history("/read_devices_viewer", {
      state: {
        companyToSelect: companyId,
        dateRangeToSelect: dateRange,
        deviceToSelect: deviceId,
        readDeviceRangeToSelect: readDeviceRange,
      },
    });

  const buttonRedirectReadDevice = (
    <FormGroup>
      <FormControlLabel
        control={
          <IconButton onClick={handleRedirect} aria-label="Ver lecturas">
            <VisibilityIcon />
          </IconButton>
        }
        label="Ver lecturas"
      />
    </FormGroup>
  );

  // add the selector of companys and the datepicker top of the page
  const buttons = (
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{ justifyContent: "center", marginBottom: 20 }}
    >
      <Grid item>
        <SelectDate
          key={JSON.stringify(date)}
          date={date.map((date: string | null) =>
            date ? new Date(date) : null
          )}
          setDate={(value) => updateFilter("date", value)}
        />
      </Grid>
      <Grid item>
        <SelectCompany
          companyId={companyId}
          companyParam={companyParam}
          setCompanyId={(value) => updateFilter("companyId", value)}
        />
      </Grid>

      <Grid item>
        <SelectDevice
          deviceId={deviceId}
          setDeviceId={(value) => updateFilter("deviceId", value)}
          whiteBg={false}
        />
      </Grid>
      <Grid item>
        <TextField
          id="fromReadDevice"
          label="Desde readDeviceId"
          type="number"
          value={readDeviceStart || ""}
          onChange={(event) =>
            updateFilter("readDeviceStart", Number(event.target.value))
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          id="toReadDevice"
          label="Hasta readDeviceId"
          type="number"
          value={readDeviceEnd || ""}
          onChange={(event) =>
            updateFilter("readDeviceEnd", Number(event.target.value))
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          id="patientId"
          label="patientId"
          type="number"
          value={patientId || ""}
          onChange={(event) =>
            updateFilter("patientId", Number(event.target.value))
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          id="takeById"
          label="takeById"
          type="number"
          value={takeById || ""}
          onChange={(event) =>
            updateFilter("takeById", Number(event.target.value))
          }
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item>
        <InputLabel id="display-patients-deleted">
          Mostrar datos pacientes eliminados
        </InputLabel>
        <Switch
          checked={patientsDeleted}
          onChange={(event) =>
            updateFilter("patientsDeleted", event.target.checked)
          }
        />
      </Grid>
      <Grid item style={{ marginLeft: 30 }}>
        <FormGroup>
          <FormControlLabel
            control={
              <IconButton onClick={handleRedirect} aria-label="Ver lecturas">
                <VisibilityIcon />
              </IconButton>
            }
            label="Ver lecturas"
          />
        </FormGroup>
      </Grid>

      <Grid item style={{ marginLeft: 30 }}>
        <FormGroup>
          <div
            onClick={restoreFilters} // Ejecuta la función al hacer clic en cualquier parte
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <RestoreIcon
              aria-label="Restaurar filtros"
              style={{ color: BACKGROUND_RED, marginRight: 8 }}
            />
            <span style={{ color: BACKGROUND_RED }}>Restaurar filtros</span>
          </div>
        </FormGroup>
      </Grid>
    </Grid>
  );

  return (
    <Page
      title={"Total de Usuarios: " + AllUsers}
      buttons={buttons}
      centerTitle
    >
      <Grid
        container
        spacing={1}
        columns={12}
        style={{ backgroundColor: "white", overflowY: "auto" }}
      >
        {/* MeasurementsGrid graphic*/}
        <Grid item xs={12} lg={6}>
          <MeasurementsGraphic
            measurements={measurements as GetReadDeviceByDayResponseDto[]}
            deviceId={deviceId}
            showExternalReadDevice={showExternalReadDevice}
            setShowExternalReadDevice={setShowExternalReadDevice}
            companyId={+companyId}
            dateRange={dateRange}
            readDeviceRange={readDeviceRange}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <SankeyGraph traces={traces as GetSankeyTracesResponseData[]} />
        </Grid>

        <Grid item xs={12} lg={6}>
          <MeasurementsDayCompanyGrid
            measurements={
              measurementsDayCampany as GetReadDeviceByDayCompanyResponseDto[]
            }
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <ActiveUsersGrid
            activeUsersByDay={activeUsers as GetActiveUsersByDayResponseDto[]}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <MeasurementsGrid
            measurements={measurements as GetReadDeviceByDayResponseDto[]}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <TranslationsGrid
            translations={translations as GetTranslateByDayResponseDto[]}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <UsersByRoleGrid
            countUsersByRole={usersByRole as GetCountUsersByRoleResponseDto[]}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export { Dashboard };
