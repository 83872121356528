import { FlexLayoutGrid } from "@doctomatic/components-react/build/DataGrid/DataGrid";
import { Page } from "../../Page";
import { UsersByRoleGridColumns } from "./UsersByRoleGridColumns";
import { Role } from "@doctomatic/sdk/build/dto/User";
import { GetCountUsersByRoleResponseDto } from "@doctomatic/sdk/build/dto/CmsDashboard/CmsDashboard";
import { GridColDef } from "@mui/x-data-grid";

export interface IUsersByRole {
  id: number;
  role: Role;
  countUsers: number;
}

export interface IUsersByRoleGrid {
  countUsersByRole?: GetCountUsersByRoleResponseDto[];
}

const UsersByRoleGrid = ({
  countUsersByRole,
}: IUsersByRoleGrid): React.ReactElement => {
  const usersByRoleDataRows: IUsersByRole[] | undefined = countUsersByRole?.map(
    (usersByRole: GetCountUsersByRoleResponseDto, index: number) => ({
      id: index,
      role: usersByRole.role,
      countUsers: usersByRole.count,
    })
  );

  const usersByRoleColumns: GridColDef<IUsersByRole>[] =
    UsersByRoleGridColumns();

  return (
    <Page title={"Usuarios por rol"} primaryColor="black">
      <FlexLayoutGrid<IUsersByRole>
        columns={usersByRoleColumns as any}
        rows={usersByRoleDataRows ? usersByRoleDataRows : []}
      />
    </Page>
  );
};

export { UsersByRoleGrid };
