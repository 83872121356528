import { useApi } from "@doctomatic/sdk/build/Api";
import { useNavigate } from "react-router-dom";
import { processError } from "../../../App/errorToast";
import { useEffect, useState } from "react";
import { Autocomplete, TextField, useTheme } from "@mui/material";

interface CompaniesSelectProp {
  companyId?: string;
  companyParam?: string | null;
  setCompanyId: (id: string) => void;
}

interface ICompany {
  id: number;
  name: string;
}

interface MappedObject {
  id: number;
  label: string;
  name: string;
}

const SelectCompany = ({
  companyId,
  companyParam,
  setCompanyId,
}: CompaniesSelectProp): React.ReactElement => {
  const theme = useTheme();
  const { useCompanies, logout } = useApi();
  const history = useNavigate();
  const { response: companiesResponse } = useCompanies(
    true,
    processError(logout, history)
  );

  const defaultValue: MappedObject = {
    id: 0,
    label: "Todos los centros",
    name: "Todos los centros",
  };

  const [companiesList, setCompaniesList] = useState<MappedObject[]>([
    defaultValue,
  ]);

  const [selectedCompany, setSelectedCompany] =
    useState<MappedObject>(defaultValue);

  useEffect(() => {
    if (companyParam) {
      const company = companiesList.find((c) => c.name === companyParam);
      if (company) {
        setCompanyId(company.id.toString());
      }
    }
  }, [companyParam, companiesList]);

  useEffect(() => {
    if (companyId !== undefined && companyId !== "" && !isNaN(+companyId)) {
      const company = companiesList.find((c) => c.id === +companyId);
      if (company) setSelectedCompany(company);
    }
  }, [companyId, companiesList]);

  useEffect(() => {
    if (companiesResponse?.data) {
      const allCompanies: ICompany[] = Array.isArray(companiesResponse?.data)
        ? [{ id: 0, name: "Todos los centros" }, ...companiesResponse?.data]
        : [];
      const aux = allCompanies.map(({ id, name }) => ({
        id,
        label: id !== 0 ? `${id} - ${name}` : name,
        name,
      }));
      setCompaniesList(aux);
    }
  }, [companiesResponse]);

  if (!companiesResponse?.data) return <></>;

  return (
    <Autocomplete
      options={companiesList || []}
      sx={{
        minWidth: 200,
        ".MuiInputBase-root": {
          color: "rgba(255, 255, 255, 0.8)", // Color del texto
        },
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "rgba(255, 255, 255, 0.8)", // Borde blanco
        },
        ".MuiAutocomplete-popupIndicator": {
          color: "rgba(255, 255, 255, 0.8)", // Flecha de apertura blanca
        },
        ".MuiAutocomplete-clearIndicator": {
          color: "rgba(255, 255, 255, 0.8)", // Cruz blanca
        },
        ".MuiInputLabel-root": {
          color: "rgba(255, 255, 255, 0.8)", // Etiqueta blanca
        },
      }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "preventOverflow",
              options: {
                boundary: "window",
              },
            },
          ],
        },
        paper: {
          sx: {
            backgroundColor: "#303030", // Fondo oscuro del desplegable
            color: "rgba(255, 255, 255, 0.8)", // Color del texto en las opciones
          },
        },
      }}
      getOptionLabel={(option) => {
        return option?.label ?? "";
      }}
      value={selectedCompany}
      onChange={(_, newValue) => {
        setCompanyId(newValue ? String(newValue.id) : "");
        setSelectedCompany(newValue ?? defaultValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={"Centro médico"}
          variant="outlined"
          fullWidth
          InputLabelProps={{ children: undefined }}
        />
      )}
    />
  );
};

export { SelectCompany };
